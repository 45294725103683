import React, { useEffect, useState } from "react";
import queryString from "query-string";

// MATERIAL UI
import { styled } from "@mui/system";
import { Typography, Button } from "@mui/material";

// COMPONENTS
import Layout from "../components/Layout";
import BookmarkCard from "../components/BookmarkCard";

const CollectionsPage = (props) => {
  const [collection, setCollection] = useState({});
  const [isInstallCtaVisible, setIsInstallCtaVisible] = useState(false);

  useEffect(() => {
    const endpoint = "https://pau-serve.herokuapp.com/graphql";

    const urlParams = queryString.parse(props.location.search);
    const collectionId = urlParams.collectionId || urlParams.cId;

    fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `{
            getCollection(id: "${collectionId}") {
              id
              title
              links {
                id
                title
                url
              }
              contributors
            }
          }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setCollection(res.data.getCollection);
      });
  }, [props.location.search]);

  const handleSaveTheseLinks = () => {
    setIsInstallCtaVisible(true);
  };

  return (
    <Layout
      metaTitle={collection.title || "Share Bookmarks to empower people"}
      metaImage="/sharedCollection.jpg"
    >
      <InstallCta
        id="install-collection-cta"
        sx={{ display: !isInstallCtaVisible ? "none" : "block" }}
      >
        <Typography variant="h3">
          Install Pau to organize your bookmarks and clone collections
        </Typography>
        <Button variant="contained" href="/">
          See more about Pau
        </Button>
      </InstallCta>
      <Typography variant="h1" sx={{ mb: 4, mt: 4 }}>
        {collection && collection.title}
        <Button
          variant="contained"
          id="subscribe-to-collection"
          onClick={handleSaveTheseLinks}
          sx={{ ml: 4 }}
        >
          Add collection to my dashboard
        </Button>
      </Typography>
      <CollectionsContainer>
        {collection &&
          collection.links &&
          collection.links.map((l) => {
            return <BookmarkCard bookmark={l} />;
          })}
      </CollectionsContainer>
      <CollectionData id="collection-data">
        {JSON.stringify(collection)}
      </CollectionData>
    </Layout>
  );
};

const InstallCta = styled("div")({
  padding: 20,
  marginTop: 40,
  borderRadius: 8,
  background: "#F876FF",
});

const CollectionsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "48% 48%",
  gap: 32,
  marginBottom: 150,

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "100%",
  },
}));

const CollectionData = styled("div")({
  position: "absolute",
  top: "-100vh",
});

export default CollectionsPage;
