import React, { useEffect, useState } from "react";

// MATERIAL UI
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

const BookmarkCard = ({ bookmark }) => {
  const [metaData, setMetaData] = useState({});

  const getMetadata = async (url) => {
    try {
      const urlToFetch = `https://jsonlink.io/api/extract?url=${url}`;
      const response = await fetch(urlToFetch);
      const dataParsed = await response.json();
      return dataParsed;
    } catch (err) {
      console.log("-> err: ", err);
    }
  };

  useEffect(() => {
    (async () => {
      const metaData = await getMetadata(bookmark.url);
      setMetaData({
        domain: metaData.domain,
        image: metaData.images[0],
      });
    })();
  }, []);

  return (
    <Container href={bookmark.url} target="_blank" rel="noreferrer">
      {Boolean(metaData.image) && <img src={metaData.image} />}
      {!Boolean(metaData.image) && (
        <Placeholder>
          <Typography variant="h3">{metaData.domain}</Typography>
        </Placeholder>
      )}
      <Typography variant="label" sx={{ mt: 1 }}>
        {bookmark.title}
      </Typography>
      <BookmarksUrl></BookmarksUrl>
    </Container>
  );
};

const Container = styled("a")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  color: "#FFFFFF",

  img: {
    borderRadius: 8,
    height: 350,
    objectFit: "cover",
  },
});

const Placeholder = styled("div")(({ theme }) => ({
  background: "#6a6a6a",
  height: 350,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 8,
}));

const BookmarksUrl = styled(Typography)({
  opacity: 0.4,
  fontSize: 16,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export default BookmarkCard;
